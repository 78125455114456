<template>
  <div>
    <!-- Components -->
    <darkBackground />
    <imageOptions />
    <renameListMutation />
    <listEdit :list="selectedList" />
    <editUserNickname :name="me?.userData?.data?.attributes?.nickname" />
    <updateImageMutation />
    <linkUploadedImageMutation />
    <deleteImageMutation />
    <forgotPasswordMutation />
    <deleteUserDataMutation />
    <updateUserNameMutation />
    <deleteUser />
    <infoOverlay :text=infoMessage index=1 />
    <!-- Components -->

    <!-- Background -->
    <hafn v-if="appName === 'HAFN'" class="hidden md:block fixed bottom-5 right-5 h-95 w-auto opacity-5 z-000"/>

    <!-- Content -->
    <div class="md:pl-24 pt-14 text-new-black font-visby">
      <div class="py-5 md:px-5 md:pr-10" :class="{'pt-safe' : parseInt(sat) !== 0}">

        <!-- Heading -->
        <div class="px-5 mt-6">
          <div class="font-visby font-bold text-3xl">Profil</div>
        </div>

        <!-- Profile Image -->
        <div class="w-full md:w-1/3 md:min-w-100 flex flex-row justify-center items-center mt-5">
          <div class="w-64 h-64 bg-white flex flex-row justify-center items-center rounded-full border-new-yellow2 border-2 shadow-soft overflow-hidden" @click="openImageOptions()">
            <div v-if="myProfileImage" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + myProfileImage + ')' }"></div>
            <div v-else class="w-full h-full p-5">
              <profileSVG class="w-full h-full" />
            </div>
          </div>
        </div>

        <!-- Profile Details -->
        <div class="px-5 mt-10 md:w-1/3  md:min-w-100">
          <div class="bg-white rounded-xl shadow-soft w-full h-auto mt-2 px-5">
            <mySettingsItem settingName="Email Adresse" :settingValue="me?.email" />
            <mySettingsItem settingName="Name" :settingValue="myName" />
            <mySettingsItem settingName="Angezeigter Name" :settingValue="me?.userData?.data?.attributes?.nickname" :editable="true" @buttonClicked="openUserNicknameEdit()" />
            <mySettingsItem :settingName="myClasses.length > 1 ? 'Klassen' : 'Klasse'" :settingValue="myClasses" />
            <mySettingsItem settingName="Aktuelles Semester" :settingValue="currentSemester" />
            <mySettingsItem settingName="App Mode" :settingValue="mode" />
            <mySettingsItem settingName="App Version" :settingValue="version" :noBorder="true" />
          </div>
        </div>

        <!-- Lists -->
        <div class="px-5 mt-5 md:w-1/3 md:min-w-100">
          <div class="font-visby font-bold text-lg">Meine Listen</div>
          <div class="bg-white rounded-xl shadow-soft w-full h-auto mt-2 px-5">
            <div v-for="(list, index) in myLists" :key="list">
              <myListItem
                :listName="list.Bezeichnung"
                :listContent="`${list.lernpfade.ids.length} Lernspots, ${list.mcFragen.ids.length} MC Fragen, ${list.pfadaufgaben.ids.length} Pfadaufgaben`"
                :border="index < myLists.length - 1"
                @buttonClicked="openList(list)" />
            </div>
          </div>
        </div>

        <!-- Additional Profile Actions -->
        <div class="px-5 mt-3 md:w-1/3  md:min-w-100">
          <myActionButton actionName="Ausloggen" @buttonClicked="logout()"/>
        </div>

        <!-- Scrollspace Mobile -->
        <div class="mb-36 md:hidden"></div>

      </div>
    </div>
  </div>
</template>

<script>
import { version } from '../../../package.json';

// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';

// Components
import mySettingsItem from './components/mySettingsItem.vue';
import myActionButton from './components/myActionButton.vue';
import imageOptions from './components/imageOptions.vue';
import darkBackground from '../../symbols/darkBackground.vue';
import infoOverlay from '../../symbols/infoOverlay.vue';
import editUserNickname from './components/editUserNickname.vue';
import myListItem from './components/myListItem.vue';
import listEdit from './components/listEdit.vue';

// Strapi
import updateUserNameMutation from './strapi/updateUserNameMutation.vue';
import updateImageMutation from './strapi/uploadImageMutation.vue';
import deleteImageMutation from './strapi/deleteImageMutation.vue';
import linkUploadedImageMutation from './strapi/linkUploadedImageMutation.vue';
import forgotPasswordMutation from '../login/strapi/forgotPasswordMutation.vue';
import deleteUserDataMutation from './strapi/deleteUserDataMutation.vue';
import deleteUser from './strapi/deleteUserMutation.vue';
import renameListMutation from './strapi/renameListMutation.vue';


// SVG
import hafn from '../../assets/svg/hafn.vue';
import profileSVG from '../../assets/svg/profile.vue';

export default {
  name: 'profile',
  mixins: [iosVibrations, detectNotch],
  components: {
    hafn,
    myListItem,
    listEdit,
    profileSVG,
    mySettingsItem,
    myActionButton,
    infoOverlay,
    imageOptions,
    darkBackground,
    updateImageMutation,
    linkUploadedImageMutation,
    deleteImageMutation,
    forgotPasswordMutation,
    deleteUserDataMutation,
    deleteUser,
    editUserNickname,
    updateUserNameMutation,
    renameListMutation,
  },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      infoMessage: null,
      selectedList: null,
      mode: process.env.NODE_ENV,
      version,
    };
  },

  computed: {
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    me() {
      return this.$store.state.profile.me;
    },
    myLists() {
      return this.me?.userData?.data?.attributes?.myLists;
    },
    currentSemester() {
      return this.$store.state.settings.currentSemester;
    },
    myName() {
      const vorname = this.me?.userData?.data?.attributes?.Vorname;
      const nachname = this.me?.userData?.data?.attributes?.Nachname;
      return `${vorname} ${nachname}`;
    },
    myProfileImage() {
      return this?.me?.userData?.data?.attributes?.avatar?.data?.attributes?.url;
    },
    myClasses() {
      const classes = [];
      if (this?.me?.klassen?.data) {
        this.me.klassen.data.forEach((klasse) => {
          classes.push(klasse?.attributes?.Bezeichnung);
        });
      }
      return classes.join(', ');
    },
    forgotPassword() {
      return this.$store.state.forgotPassword;
    },
    deleteUserData() {
      return this.$store.state.deleteUserData;
    },
    deleteUser() {
      return this.$store.state.deleteUser;
    },
  },

  beforeMount() {
    this.queryMeEx();
  },

  methods: {

    openList(list) {
      this.iosVibrate(0);
      this.selectedList = list;
      this.$store.commit('showOverlay', 'listEdit');
    },

    openUserNicknameEdit() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'editUserNickname');
    },

    openImageOptions() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'imageOptions');
    },

    logout() {
      this.$store.dispatch('logoutUser');
    },

    toggleInfoWindow(message) {
      this.infoMessage = message;
      this.iosVibrate(1);
      this.$store.commit('toggleInfoOverlay', { value: true, index: 1 });
    },
  },
};
</script>
