<template>
  <div class="font-visby text-new-black py-5 bg-white shadow-soft rounded-xl text-center cursor-pointer mb-3" @click="$emit('button-clicked')">
    <div class="underline">{{ actionName }}</div>
  </div>
</template>

<script>

export default {
  name: 'myActionButton',
  props: ['actionName'],
  emits: ['button-clicked'],
};
</script>
