<template>
  <div class="font-visby text-new-black py-5 cursor-pointer" :class="{'border-b border-new-yellow2' : !noBorder}">
    <div class="w-full flex flex-row justify-between">
      <div>{{ settingName }}</div>
      <div v-if="editable" class="underline cursor-pointer" @click="$emit('button-clicked')">Bearbeiten</div>
    </div>
    <div class="text-sm text-new-midGrey">{{ settingValue || '-' }}</div>
  </div>
</template>

<script>

export default {
  name: 'mySettingsItem',
  props: ['settingName', 'settingValue', 'editable', 'noBorder'],
  emits: ['button-clicked'],
};
</script>
