<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation, fetch } from 'villus';
import { multipart } from '@villus/multipart';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateImageMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
      use: [multipart(), fetch()],
    });

    const updateImageMutation = gql`
      mutation($file: Upload!) {
        upload(file: $file) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateImage } = useMutation(updateImageMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateImage', result: updateImage });
  },
};
</script>
