<template>
<transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
  <div v-if="showOverlay === 'imageOptions'" class="w-full h-auto md:h-full fixed bottom-0 md:inset-0 z-30 flex flex-row justify-center items-center pointer-events-none font-visby text-new-black">
    <div class="w-full md:w-auto md:min-w-1/3 h-auto md:h-auto bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 pb-10 md:p-12 pointer-events-auto">

        <div class="relative py-5 bg-white shadow-soft rounded-xl text-center cursor-pointer mb-3 flex flex-row justify-center items-center">
          <div v-if="!loading" class="underline">Profilbild ändern</div>
           <spinSVG v-else :light="false" />
          <input type="file" accept="image/*" class="absolute opacity-0 inset-0 outline-none appearance-none" @change="changeProfileImage($event)" />
        </div>
        <myActionButton actionName="Profilbild löschen" @buttonClicked="removeProfileImage()"/>
        <div v-if="errorMessage !== null" class="text-red-500 p-5">{{ errorMessage }}</div>

    </div>
  </div>
</transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import myActionButton from './myActionButton.vue';

// SVG
import spinSVG from '../../../assets/svg/spin.vue';

export default {
  name: 'imageOptions',
  mixins: [slideInOutBottom, iosVibrations],
  components: { myActionButton, spinSVG },

  data() {
    return {
      errorMessage: null,
      loading: false,
    };
  },

  computed: {
    userDataId() {
      return this.$store.state.profile.me.userData.data.id;
    },
    imageId() {
      return this.$store.state.profile?.me?.userData?.data?.attributes?.avatar?.data?.id;
    },
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    updateImage() {
      return this.$store.state.updateImage;
    },
    linkUploadedImage() {
      return this.$store.state.linkUploadedImage;
    },
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    deleteImage() {
      return this.$store.state.deleteImage;
    },
  },

  watch: {
    showOverlay() {
      if (this.showOverlay === null) {
        this.errorMessage = null;
      }
    },
  },

  methods: {
    changeProfileImage(e) {
      this.loading = true;
      const file = e.target.files[0];
      if (this.imageId) {
        this.uploadImage(file, true);
      } else {
        this.uploadImage(file, false);
      }
    },

    uploadImage(file, deleteOldImage) {
      this.updateImage({ file }).then((result) => {
        if (result.error) {
          this.errorMessage = result.error;
          this.loading = false;
        } else {
          const imageID = result.data.upload.data.id;
          this.linkUploadedImage({ id: this.userDataId, avatar: imageID }).then((result2) => {
            if (result2.error) {
              this.errorMessage = result2.error;
              this.loading = false;
            } else if (deleteOldImage) {
              this.removeProfileImage();
            } else {
              this.queryMeEx();
              this.hideImageOptions();
            }
          });
        }
      });
    },

    removeProfileImage() {
      this.deleteImage({ id: this.imageId }).then((result) => {
        if (result.error) {
          this.errorMessage = result.error;
          this.loading = false;
        } else {
          this.queryMeEx();
          this.hideImageOptions();
        }
      });
    },

    hideImageOptions() {
      this.iosVibrate(0);
      this.errorMessage = null;
      this.loading = false;
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
