<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name: 'forgotPasswordMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const forgotPasswordMutation = gql`
      mutation($email: String!) {
        forgotPassword(email: $email) {
          ok
        }
      }
      `;

    const { execute: forgotPassword } = useMutation(forgotPasswordMutation);
    store.commit('setStrapiExecutionHandler', { item: 'forgotPassword', result: forgotPassword });
  },
};
</script>
