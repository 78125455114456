<template>
<transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
  <div v-if="showInfoOverlay && index == showInfoOverlayIndex" class="w-full h-screen flex md:flex-row flex-col md:justify-center md:items-center fixed top-0 font-visby z-40 text-new-black">
    <div class="md:absolute w-full h-full md:flex md:flex-row md:justify-center md:items-center" @click="hideInfoOverlay">
      <div class="absolute md:static bottom-0 w-full md:w-auto md:max-w-80 h-auto md:h-auto bg-white rounded-t-3xl md:rounded-3xl p-5 md:p-12 pb-12">
        <div class="uppercase tracking-widest w-full text-center text-sm underline mb-8 cursor-pointer">Schließen</div>
        <div v-if="!json" class="text-lg text-center">{{text}}</div>
        <div v-else class="px-5">
          <tipTap :content="text" displayType="clean" />
        </div>
        <div v-if="button" class="w-full p-5">
          <customButton :label=button @click="$emit('buttonClicked')" />
        </div>
    </div>
  </div>
  </div>
</transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../mixins/iosVibrations.vue';

// Components
import customButton from './customButton.vue';
import tipTap from './tipTap.vue';

export default {
  name: 'infoOverlay',
  props: ['text', 'button', 'index', 'denyClosing', 'level', 'json'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { customButton, tipTap },
  emits: ['buttonClicked'],

  computed: {
    showInfoOverlay() {
      return this.$store.state.layout.showInfoOverlay;
    },
    showInfoOverlayIndex() {
      return this.$store.state.layout.showInfoOverlayIndex;
    },
  },

  methods: {
    hideInfoOverlay() {
      if (!this.denyClosing) {
        this.iosVibrate(0);
        this.$store.commit('toggleInfoOverlay', { value: false, level: this.level });
      }
    },
  },
};
</script>
